import PageWrapper from './PageWrapper';

const Home = () => {
	return (
		<PageWrapper forceReadableWidth>
			<h1>What I Do</h1>
			<p>17 y/o developer, designer, and innovator.</p>
		</PageWrapper>
	);
};

export default Home;
