import type { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const ConditionalWrapper = ({
	condition,
	wrapper,
	children
}: {
	condition: boolean;
	wrapper: (children: ReactNode) => ReactElement;
	children: ReactElement;
}) => (condition ? wrapper(children) : children);

const Technology = ({
	color,
	icon,
	name,
	type,
	useCase,
	url
}: {
	color: string;
	icon: ReactElement;
	name: string;
	type: string;
	useCase: string;
	url?: string;
}) => {
	return (
		<>
			<ConditionalWrapper
				condition={url !== undefined}
				wrapper={(children) => (
					<A href={url} target="_blank" rel="noopener">
						{children}
					</A>
				)}
			>
				<Container>
					<Head color={color}>{icon}</Head>

					<Column forceWidth={110}>
						<h5>name</h5>
						<p>{name}</p>
					</Column>
					<Column forceWidth={200}>
						<h5>type</h5>
						<p>{type}</p>
					</Column>
					<Column noBorder>
						<h5>use case</h5>
						<p>{useCase}</p>
					</Column>
				</Container>
			</ConditionalWrapper>
		</>
	);
};

const A = styled.a`
	text-decoration: none;
	color: inherit;

	&:hover {
		text-decoration: none !important;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100px;
	border: 1px solid #101010;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 1rem;

	@media (max-width: 850px) {
		flex-direction: column;
		height: auto;
	}

	&:hover {
		background-color: #101010;
	}
`;

const Head = styled.div<{ color: string }>`
	height: 100%;
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	fill: #fff;
	background-color: ${({ color }) => color};
	flex-shrink: 0;

	@media (max-width: 850px) {
		height: 50px;
		width: 100%;
	}

	svg {
		height: 30px;
		width: 30px;
	}
`;

const Column = styled.div<{ forceWidth?: number; noBorder?: boolean }>`
	display: block;
	height: 100%;
	width: ${({ forceWidth }) => (forceWidth ? `${forceWidth}px` : 'fit-content')};
	min-width: ${({ forceWidth }) => (forceWidth ? `${forceWidth}px` : undefined)};
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-right: 1px solid ${({ noBorder }) => (noBorder ? 'transparent' : '#101010')};
	padding: 1rem;
	box-sizing: border-box;
	flex-shrink: ${({ noBorder }) => (noBorder ? undefined : 0)};

	@media (max-width: 850px) {
		height: 50px;
		width: 100%;
		border-bottom: 1px solid ${({ noBorder }) => (noBorder ? 'transparent' : '#101010')};
		padding: 1rem;
		flex-shrink: 0;
		box-sizing: content-box;
	}

	h5 {
		font-family: 'Courier New';
		margin: 0;
		color: #fff;
	}

	p {
		margin: 0;
		white-space: normal;
	}
`;

export default Technology;
